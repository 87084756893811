import React from "react";
import {
  IoIosArrowForward,
  IoIosTimer,
  IoIosPause,
  IoIosSearch,
  IoIosPeople,
  IoIosEye,
  IoIosContact,
  IoIosCart,
  IoIosFlash,
  IoIosCheckbox,
  IoIosCheckboxOutline,
  IoIosFiling,
  IoIosHappy,
  IoMdAnalytics,
  IoIosSettings,
  IoIosHeart,
  IoIosList,
} from "react-icons/io";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  CallToAction,
  CustomerQuote,
  LogoScroller,
  ImageGraphic,
  Modal,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Retail = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Payroll Software for Retail Businesses | PayHero"
        description="Focus on selling more. Let payroll take care of itself. PayHero is the online payroll and payday filing system for New Zealand retailers. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">Payroll for Retail</h1>
                <h2 className="h1 -fontLarge">
                  Focus on selling more{" "}
                  <span className="-fontPrimary">
                    and let payroll take care of itself
                  </span>
                </h2>
                <h4>
                  Look after your staff, get their entitlements right and
                  understand your wage costs. PayHero is the online payroll and
                  payday filing system for New Zealand retailers.
                </h4>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
              <LogoScroller>
                <Image
                  filename="FlexiTimeRetailCustomers.png"
                  alt="PayHero Retail Payroll Customers"
                  centerImage
                />
              </LogoScroller>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="RetailHero.jpg"
                alt="Payroll for Retail Businesses"
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosCheckbox />
                <br />
                Stress free payroll compliance
              </h3>
              <p>
                Retail is a hot bed of potentially tricky payroll scenarios with
                part time and variable hour staff and regularly changing work
                patterns. PayHero follows the latest MBIE Holidays Act
                guidelines to ensure your employees maintain the correct holiday
                entitlements, even when things change.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosContact />
                <br />
                Take the time out of timesheets
              </h3>
              <p>
                Managing employee time is a breeze in PayHero. Use our time
                clock app to send accurate timesheets straight to payroll and
                automatically manage rest and meal breaks. Photos are captured
                at clock in and out so you can ensure staff are dressed
                appropriately and ready for their shift.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCart />
                <br />
                Easily manage multiple stores
              </h3>
              <p>
                Manage time, leave and payroll for different locations with
                PayHero Teams. Assign staff members to one or more stores and
                give local managers access to manage leave approvals and
                timesheets at their site. Track your wage costs with Xero
                tracking category integration.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={40}>
              <h2>
                Photo Time Clock
                <br />
                <span className="-fontLight">
                  Record exact clock in and clock out times for fast, accurate
                  payroll.
                </span>
              </h2>
              <Feature
                icon={<IoIosTimer key={0} />}
                title="Automatic timesheets"
                description="Clock in and outs automatically update timesheets in your payroll. No more collecting paper timesheets, checking the hours have been added up correctly and typing them into your payroll system."
              />
              <Feature
                icon={<IoIosPause key={0} />}
                title="Seamlessly manage paid & unpaid breaks"
                description="When your staff record breaks on the time clock app, PayHero will automatically figure out if the break should be a paid 10-minute rest break or an unpaid 30-minute meal break."
              />
              <Feature
                icon={<IoIosEye key={0} />}
                title="Visibility of who's onsite"
                description="Get a head start on the health & safety requirements of your venue by always having a record of who’s currently working onsite at any time."
              />
              <Link className="link-arrow" to="/timeclock">
                Discover Photo Time Clock <IoIosArrowForward />
              </Link>
            </Box>
            <Box size={50}>
              <Image
                alt="Photo Time Clock | PayHero"
                filename="PayHeroShift_ClockInEntry.png"
                addShadow
                centerImage
                rounded
                maxWidth={500}
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="We can see at a glance that the person clocking in is who it should be. We can also check their appearance and ensure they’re wearing our clothing, which is important in a retail environment."
            name="Penny Ginn"
            company="Gregory"
            pic="Gregory_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="-textCenter">
            Look after your bottom line and let PayHero take care of the tricky
            payroll stuff
          </h2>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="PayrollForRetail_PayHero.jpg"
                alt="PayHero | Payroll Software for Retail"
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40}>
              <Feature
                icon={<IoIosCheckboxOutline key={0} />}
                title="Easy public holiday payroll"
                description="PayHero determines whether a public holiday is an otherwise working day for an employee based on their recent work pattern and calculates their entitlements accordingly."
              />
              <Feature
                icon={<IoIosTimer key={0} />}
                title="Take the time out out of timesheets"
                description="Managing employee time is a breeze in PayHero. Use our time clock app to send accurate timesheets straight to payroll. Use our optional Timesheet Approvals feature to ensure management has approved shifts before they are paid. Photos are captured at clock in and out so you can ensure staff are dressed appropriately and ready for their shift. Rest and meal breaks are also automatically managed in PayHero."
              />
              <Feature
                icon={<IoIosFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    IRD and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
              <Feature
                icon={<IoIosPeople key={0} />}
                title="Employee self-service"
                description="Save time and hassle by letting your staff view leave balances and request leave from the employee app. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoIosFiling key={0} />}
                title="Update your accounts"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed break down."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="My PAYE data is sent to IRD automatically after each pay run so I don’t have to worry about it again."
            name="Robert Watt"
            company="The Source Bulk Foods"
            pic="SourceBulkFoods_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">
            PayHero makes retail <Link to="/payroll">payroll</Link> easy
          </h2>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoIosList />
                  <br />
                  Works the way your business does
                </h3>
                <p>
                  Easily set up different departments – such as bar, kitchen,
                  front of house – for clocking in and reporting on costs.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoMdAnalytics />
                  <br />
                  Insight into finances and staffing
                </h3>
                <p>
                  Track your wage costs across departments, ensuring you never
                  go over budget. Compare staff costs with your revenue to
                  ensure you have the right number of staff rostered on at busy
                  and quiet periods.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosSettings />
                  <br />
                  Easy set up
                </h3>
                <p>
                  Grab an iPad or Android tablet, login to PayHero's photo time
                  clock app and you’re ready to go. No huge setup costs or
                  annual maintenance fees like with fingerprint scanners or card
                  based systems.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoIosHappy />
                  <br />
                  Free support
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosSearch />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHeart />
                  <br />
                  PayHero loves Retail
                </h3>
                <p>
                  We love retail as much as you do. Choose a payroll software
                  provider that understands your challenges and wants to help
                  you succeed.
                </p>
              </Box>
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction
        title="Retail payroll made super easy"
        description="14 Days Free · First Pay Walkthrough · No Credit Card Required"
        showSalesButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Retail;
